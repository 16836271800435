const LIST_STYLE_TIME = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image1.png',
        cssStyle: `
        .box--time__1 {
            height: 300px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        .box--time__1 .body-frame {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .box--time__1 .body-frame .container {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .box--time__1 .body-frame .container .right__time .title {
            padding: 10px;
            background-color: #fff;
            color: #36a6b4;
            border-radius: 10px;
        }
        .box--time__1 .body-frame .container .left__time .date__time {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .box--time__1 .body-frame .container .left__time .charactor_time {
            text-align: center;
            margin-block: 2px;
            color: #fff;
        }
        `,
        contents: `<div id="sourceBgr" class="box--time__1" style="background-image: url(《sourceBgr》)"><div class="body-frame"><div class="container"><div class="right__time"><p id="shotTitle" class="title">《タイトル》</p></div><div class="left__time"><div class="date__time"><h4 id="shotStartTime" style="color: #ffffff">《開始》</h4></div><div class="charactor_time">▼</div><div class="date__time"><h4 id="shotEndTime" style="color: #ffffff">《終了》</h4></div></div></div></div></div>`
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image2.png',
        cssStyle: `
       .box--time__2 {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 415px;
            position: relative;
        }
        .box--time__2 .content__title {
            padding: 50px 0px;
        }
        .box--time__2 .content__title h3 {
            text-align: center;
            color: #fff;
        }
        .box--time__2 .body__2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .box--time__2 .container {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .box--time__2 .container .box__time .charactor_time {
            text-align: center;
            margin-block: 0px;
            color: #fff;
            font-size: 20px;
            margin: 20px 0px;
        }
        .box--time__2 .container .box__time h5 {
            color: #fff;
            text-align: center;
        }
       `,
        contents: `<div id="sourceBgr" class="box--time__2" style="background-image: url(《sourceBgr》)"><div class="content__title"><h3 id="shotTitle">《タイトル》</h3></div><div class="body__2"><div class="container"><div class="box__time"><div><h5 id="shotStartTime">《開始》</h5></div><div class="charactor_time">▼</div><div><h5  id="shotEndTime">《終了》</h5></div></div></div></div></div>`
    },
    {
        id: 3,
        name: 'C',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image3.png',
        cssStyle: `
         .box--time__3 {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 294px;
            width: 100%;
        }
        .box--time__3 .body-frame {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .box--time__3 .body-frame .container {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .box--time__3 .body-frame .container .right__time .title {
            padding: 10px;
            background-color: #5d6b8f;
            color: #fff;
            border-radius: 10px;
        }
        .box--time__3 .body-frame .container .left__time .date__time {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .box--time__3 .body-frame .container .left__time .date__time .text {
            color: #5d6b8f;
        }
        .box--time__3 .body-frame .container .left__time .charactor_time {
            text-align: center;
            margin-block: 2px;
            color: #5d6b8f;
        }
         `,
        contents: `<div id="sourceBgr" class="box--time__3" style="background-image: url(《sourceBgr》)"><div class="body-frame"><div class="container"><div class="right__time"><p id="shotTitle" class="title">《タイトル》</p></div><div class="left__time"><div class="date__time"><h4 id="shotStartTime" class="text">《開始》</h4></div><div class="charactor_time">▼</div><div class="date__time"><h4  id="shotEndTime" class="text">《終了》</h4></div></div></div></div></div>`
    },
    {
        id: 4,
        name: 'D',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/image4.png',
        cssStyle: `
        .box--time__4 {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 346px;
            width: 100%;
        }
        .box--time__4 .body-frame {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .box--time__4 .body-frame .container {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .box--time__4 .body-frame .container .right__time .title {
            padding: 10px;
            background-color: #947be8;
            color: #fff;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .box--time__4 .body-frame .container .left__time .date__time {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .box--time__4 .body-frame .container .left__time .date__time .text {
            color: #947be8;
        }
        .box--time__4 .body-frame .container .left__time .charactor_time {
            text-align: center;
            margin-block: 2px;
            color: #947be8;
        }
        `,
        contents: `<div id="sourceBgr" class="box--time__4" style="background-image: url(《sourceBgr》)"><div class="body-frame"><div class="container"><div class="right__time"><p id="shotTitle" class="title">《タイトル》</p></div><div class="left__time"><div class="date__time"><h4 id="shotStartTime" class="text">《開始》</h4></div><div class="charactor_time">▼</div><div class="date__time"><h4  id="shotEndTime" class="text">《終了》</h4></div></div></div></div></div>`
    }
];

const LIST_STYLE_PRIZE = [
    {
        id: 1,
        name: 'A',
        srcBgr: '/images/landingpage/style_prize_1.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_prize_1.png"alt=""/><div class="box--name__block __1"><h2 class="text">《titleBlock》</h2></div></div>',
        cssStyle: `
        .box--prize__1 {
            position: relative;
        }
        .box--prize__1 .bgr__prize {
            width: 100%;
            height: 100%;
        }
        .box--prize__1 .prize--title {
            position: absolute;
            top: 5rem;
            left: -2rem;
            text-align: center;
            transform: rotate(-40deg);
            width: 290px;
            height: 70px;
            overflow: hidden;
        }
        .box--prize__1 .prize--title .text {
            font-size: 2em;
            color: #fff;
            font-weight: bold;
        }
        .box--prize__1 .frame--img {
            position: absolute;
            width: 400px;
            height: 300px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
        }
        .box--prize__1 .frame--img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        .box--prize__1 .frame--detail {
            position: absolute;
            bottom: -1rem;
            left: 2rem;
            padding: 20px;
            max-width: 600px;
        }
        .box--prize__1 .frame--detail .text {
            color: #000;
        }
        .box--prize__1 .frame--number__winner {
            position: absolute;
            bottom: 0;
            right: 15px;
            padding: 20px;
        }
        .box--prize__1 .frame--number__winner .text {
            color: #36a6b4;
        }
        `,
        contents: `<div class="box--prize__1" style="display: 《display》"><img class="bgr__prize" src="《sourceBgr》" alt="" /><div class="prize--title"><h4 class="text">《タイトル》</h4></div><div class="frame--img"><img src="《imgTitle》" alt="" /></div><div class="frame--detail"><h2 class="text">《詳細》</h2></div><div class="frame--number__winner"><h2 class="text">《当選人数》名様</h2></div></div>`
    },
    {
        id: 2,
        name: 'B',
        srcBgr: '/images/landingpage/style_prize_2.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_style_2.png"alt=""/><div class="box--name__block __2"><h2 class="text">《titleBlock》</h2></div></div>',
        cssStyle: `
        .box--prize__2 {
            position: relative;
        }
        .box--prize__2 img {
            width: 100%;
            height: 100%;
        }
        .box--prize__2 .prize--title {
            position: absolute;
            top: 4rem;
            left: 50%;
            transform: translateX(-50%);
            width: 218px;
        }
        .box--prize__2 .prize--title .text {
            color: #efea3a;
            font-size: 2em;
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            text-align: center;
            text-shadow: 2px 2px 0px #25ab38, -2px -2px 0px #25ab38, -2px 2px 0px #25ab38, 2px -2px 0px #25ab38, 0px 2px 0px #25ab38, 0px -2px 0px #25ab38, 2px 0px 0px #25ab38, -2px 0px 0px #25ab38;
        }
        .box--prize__2 .frame--img {
            position: absolute;
            width: 400px;
            height: 300px;
            overflow: hidden;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .box--prize__2 .frame--img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        .box--prize__2 .frame--detail {
            position: absolute;
            bottom: 0;
            left: 2rem;
            padding: 20px;
            max-width: 520px;
        }
        .box--prize__2 .frame--detail .text {
            color: #000;
        }
        .box--prize__2 .frame--number__winner {
            position: absolute;
            bottom: 0;
            right: 15px;
            padding: 20px;
        }
        .box--prize__2 .frame--number__winner .text {
            color: #25ab38;
        }
        `,
        contents: `<div class="box--prize__2" style="display: 《display》"><img src="《sourceBgr》" alt="" /><div class="prize--title"><h4 class="text">《タイトル》</h4></div><div class="frame--img"><img src="《imgTitle》" alt="" /></div><div class="frame--detail"><h2 class="text">《詳細》</h2></div><div class="frame--number__winner"><h2 class="text">《当選人数》名様</h2></div></div>`
    },
    {
        id: 3,
        name: 'C',
        srcBgr: '',
        htmlTitle:
            '<div class="frame__block __3"><img src="/images/landingpage/title_prize_3.png"alt=""/><div class="box--name__block __3"><h2 class="text">《titleBlock》</h2></div></div>',
        cssStyle: ``,
        contents: `<div class="box--prize__3" style="display: 《display》"><div class="box--info"><div class="prize--title"><h4 class="text">《タイトル》</h4></div><div class="frame--img"><img src="《imgTitle》" alt="" /></div><div class="frame--detail"><h2 class="text">《詳細》</h2></div><div class="frame--number__winner"><h2 class="text">《当選人数》名様</h2></div></div></div>`
    }
];

const LIST_STYLE_TARGET_PRODUCT = [
    {
        id: 1,
        name: 'A',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739269314_7y3UQUFbng_style_prize_1.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739332647_rVOEqjEQLn_1212.png',
        contents: ''
    },
    {
        id: 2,
        name: 'B',
        srcBgr: 'https://api-paldia.supenient.vn/storage/images/1739334790_L2PGFBYFUa_stype_prize_2.png',
        imgSection: 'https://api-paldia.supenient.vn/storage/images/1739333660_GNF2r28m0T_Group.png',
        contents: ''
    }
];

const LIST_STYLE_APPLICATION_METHODS = [
    {
        id: 1,
        name: 'A',
        srcBgr: '/images/landingpage/ptut_style_1.png',
        stepJoin: '/images/landingpage/step_style_1.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_ptut_style_1.png"alt=""/><div class="box--name__block __1"><h2 class="text">《titleBlock》</h2></div></div>',
        contents: `<div class="box--methods__1" style="display: 《display》"><img src="《sourceBgr》" alt="" /><div class="method--title"><h4 class="text">《タイトル》</h4></div><div class="frame--img"><img src="《imgTitle》" alt="" /></div><div class="frame--detail"><h4 class="text">《詳細》</h4></div></div>`
    },
    {
        id: 2,
        name: 'B',
        srcBgr: '/images/landingpage/ptut_style_2.png',
        stepJoin: '/images/landingpage/step_style_2.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_ptut_style_2.png"alt=""/><div class="box--name__block __2"><h2 class="text">《titleBlock》</h2></div></div>',
        contents: `<div class="box--methods__2" style="display: 《display》"><img src="《sourceBgr》" alt="" /><div class="method--title"><h4 class="text">《タイトル》</h4></div><div class="frame--img"><img src="《imgTitle》" alt="" /></div><div class="frame--detail"><h4>《詳細》</h4></div></div>`
    }
];
const LIST_STYLE_APPLICATION_RULE = [
    {
        id: 1,
        name: 'A',
        srcBgr: '/images/landingpage/ptut_style_1.png',
        stepJoin: '/images/landingpage/step_style_1.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_ptut_style_1.png"alt=""/><div class="box--name__block __1"><h2 class="text">《titleBlock》</h2></div></div>',
        cssStyle: `
        .frame__block {
            position: relative;
            margin-bottom: 1rem;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
        .frame__block img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .frame__block .box--name__block {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            width: 100%;
            overflow: hidden;
        }
        .frame__block .box--name__block.__1 {
            max-width: 320px;
        }
        .frame__block .box--name__block.__1 .text {
            color: #fff;
        }
        `,
        contents: `<div class="content-rule"></div>`
    },
    {
        id: 2,
        name: 'B',
        srcBgr: '/images/landingpage/ptut_style_2.png',
        stepJoin: '/images/landingpage/step_style_2.png',
        htmlTitle:
            '<div class="frame__block"><img src="/images/landingpage/title_style_2.png"alt=""/><div class="box--name__block __2"><h2 class="text">《titleBlock》</h2></div></div>',
        cssStyle: `
        .frame__block {
            position: relative;
            margin-bottom: 1rem;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
        .frame__block img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .frame__block .box--name__block {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            width: 100%;
            overflow: hidden;
        }
        .frame__block .box--name__block.__2 {
            max-width: 520px;
        }
        .frame__block .box--name__block.__2 .text {
            color: #343a40;
        }
        `,
        contents: `<div id="content-rule"></div>`
    }
];

const LIST_STYLE_CONTACT = [
    {
        id: 1,
        name: 'A',
        srcBgr: '/images/landingpage/style_contact_1.png',
        imgSection: '/images/landingpage/style_1_title_contact.png',
        contents: `<div><div class="frame__block"><img id="sourceImg" src="《imgSection》" alt="" /><div class="box--name__block __1"><h2 id="shotTitle" class="text">《titleBlock》</h2></div></div><div id="sourceBgr" class="box--contact__1" style="background-image: url(《sourceBgr》);background-size: cover;"><div class="body-frame"><div class="container"><div><p id="shotCompany" class="name__company">《お問い合わせ事務局名》</p><span class="note_company">キャンペーン専用窓口</span></div><p id="shotPhone" class="phone__number">《TEL》</p><div><p id="shotTime" class="time_recived">受付時間： 00:00 ~ 00:00</p><span class="note_time">（祝日を除く月～金曜日）</span></div></div></div></div></div>`,
        cssStyle: `
        .frame__block {
            position: relative;
            margin-bottom: 1rem;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
        .frame__block img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .frame__block .box--name__block {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            width: 100%;
            overflow: hidden;
        }
        .frame__block .box--name__block.__1 {
            max-width: 350px;
        }
        .frame__block .box--name__block.__1 .text {
            color: #fff;
        }
        .box--contact__1 .body-frame {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0px;
            color: #fff;
            font-size: 1.5rem;
            text-align: center;
        }
        .box--contact__1 .body-frame .container .phone__number {
            color: #000;
            font-weight: bold;
            text-shadow: 2px 2px 0px #fff, -2px -2px 0px #fff, -2px 2px 0px #fff, 2px -2px 0px #fff, 0px 2px 0px #fff, 0px -2px 0px #fff, 2px 0px 0px #fff, -2px 0px 0px #fff;
        }
        `
    },
    {
        id: 2,
        name: 'B',
        srcBgr: '/images/landingpage/style_contact_2.png',
        imgSection: '/images/landingpage/title_style_2.png',
        contents: `<div><div class="frame__block"><img id="sourceImg" src="《imgSection》" alt="" /><div class="box--name__block __2"><h2 id="shotTitle" class="text">《titleBlock》</h2></div></div><div id="sourceBgr" class="box--contact__2" style="background-image: url(《sourceBgr》);background-size: cover;"><div class="body-frame"><div class="container"><div><p id="shotCompany" class="name__company">《お問い合わせ事務局名》</p><span class="note_company">キャンペーン専用窓口</span></div><p id="shotPhone" class="phone__number">《TEL》</p><div><p id="shotTime" class="time_recived">受付時間： 00:00 ~ 00:00</p><span class="note_time">（祝日を除く月～金曜日）</span></div></div></div></div></div>`,
        cssStyle: `
        .frame__block {
            position: relative;
            margin-bottom: 1rem;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
        .frame__block img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .frame__block .box--name__block {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            width: 100%;
            overflow: hidden;
        }
        .frame__block .box--name__block.__2 {
            max-width: 520px;
        }
        .frame__block .box--name__block.__2 .text {
            color: #fff;
        }
        .box--contact__2 .body-frame {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0px;
            color: #fff;
            font-size: 1.5rem;
            text-align: center;
        }
        .box--contact__2 .body-frame .container .phone__number {
            color: #25AB38;
            font-weight: bold;
            text-shadow: 2px 2px 0px #fff, -2px -2px 0px #fff, -2px 2px 0px #fff, 2px -2px 0px #fff, 0px 2px 0px #fff, 0px -2px 0px #fff, 2px 0px 0px #fff, -2px 0px 0px #fff;
        }
        `
    }
];

const LIST_STYLE_BUTTON_BLOCK = [
    {
        id: 1,
        name: 'A'
    },
    {
        id: 2,
        name: 'B'
    }
];

export {
    LIST_STYLE_TIME,
    LIST_STYLE_PRIZE,
    LIST_STYLE_TARGET_PRODUCT,
    LIST_STYLE_APPLICATION_METHODS,
    LIST_STYLE_CONTACT,
    LIST_STYLE_BUTTON_BLOCK,
    LIST_STYLE_APPLICATION_RULE
};
