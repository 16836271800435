import { formatDateCommon, formatMergeDateAndTime, STR_DATE_TIME } from '@/utils/format';
import { showMessage } from '@/utils/messages';
import { TABS, SOCIAL_MEDIA_SHARE } from './constants';
import {
    LIST_STYLE_TIME,
    LIST_STYLE_PRIZE,
    LIST_STYLE_TARGET_PRODUCT,
    LIST_STYLE_APPLICATION_METHODS,
    LIST_STYLE_CONTACT,
    LIST_STYLE_APPLICATION_RULE
} from './styles';
import { landingPageService } from '@/api/services/landing-page.service';
import moment from 'moment';
import i18n from '@/i18n';
import Swal from 'sweetalert2';

function handleDataBeforeSaveLP(dataForm) {
    return {
        campaign: dataForm?.campaign?.id
            ? {
                  id: dataForm?.campaign?.id
              }
            : null,
        instruction_pic: dataForm.sale_staff?.id
            ? {
                  id: dataForm?.sale_staff?.id
              }
            : null,
        business_pic: dataForm.person_in_charge?.id
            ? {
                  id: dataForm?.person_in_charge?.id
              }
            : null,
        name: dataForm.name,
        url_public: dataForm.url_public,
        lp_start_date: formatMergeDateAndTime(dataForm.lp_start_date, dataForm.lp_start_time),
        lp_end_date: formatMergeDateAndTime(dataForm.lp_end_date, dataForm.lp_end_time),
        campaign_start_date: formatMergeDateAndTime(dataForm.campaign_start_date, dataForm.campaign_start_time),
        campaign_end_date: formatMergeDateAndTime(dataForm.campaign_end_date, dataForm.campaign_end_time),
        status: dataForm.status,
        keywords: dataForm.keywords,
        description: dataForm.description,
        analytics: dataForm.analytics,
        og_image: dataForm?.og_image?.id
            ? {
                  id: dataForm?.og_image?.id
              }
            : null,

        ...(dataForm.id !== null &&
            dataForm.id !== undefined && {
                id: dataForm.id,

                //----- start setting blocks --------------
                block_arrangement: dataForm.blocks_settings.map((itemBlock) => {
                    return {
                        type: itemBlock.block_default ? 'predefined' : 'custom',
                        name: itemBlock.name,
                        position: itemBlock.order_index,
                        status: itemBlock.is_visibled ? 1 : 0,
                        category: itemBlock.category ?? '',
                        key: itemBlock.key ?? ''
                    };
                }),
                //----- end setting blocks --------------

                //----- start setting firstview --------------
                first_view: {
                    html_content: dataForm.first_view?.html_content
                },
                application_period: {
                    title: dataForm.application_period.title,
                    style_id: dataForm.application_period.style?.id ?? null,
                    start_date: dataForm.application_period.start_date,
                    end_date: dataForm.application_period.end_date,
                    html_content: dataForm.application_period.html_content
                },
                //----- end setting firstview --------------

                //----- start setting prize settings  --------------
                block_prize: {
                    title_block: dataForm.prize_settings.title ?? '',
                    background_image: dataForm.prize_settings.background.image?.id
                        ? {
                              id: dataForm.prize_settings.background.image?.id
                          }
                        : null,
                    background_color: dataForm.prize_settings.background.color ?? '#ffffff',
                    is_repeat: dataForm.prize_settings.is_repeat || false,
                    style: dataForm.prize_settings.style?.id ?? null,
                    prizes: dataForm.prize_settings.prizes.map((preItem, idx) => {
                        return {
                            ...(preItem.id !== null &&
                                preItem.id !== undefined && {
                                    id: preItem.id
                                }),
                            order_index: idx,
                            title: preItem.title,
                            image: {
                                id: preItem?.image?.id ?? null
                            },
                            detail: preItem.detail,
                            number_of_winner: preItem.number_of_winner,
                            status: preItem.status
                        };
                    }),
                    html_content: dataForm.prize_settings.html_content ?? ''
                },
                //----- end setting prize settings  --------------

                //----- start setting target product settings  --------------
                block_target_product: {
                    ...dataForm?.block_target_product,
                    style: dataForm.block_target_product.style?.id ?? null,
                    background_image: dataForm?.block_target_product?.background_image?.id
                        ? {
                              id: dataForm?.block_target_product?.background_image?.id
                          }
                        : null,
                    target_products: dataForm.block_target_product.target_products.map((itemTargetPro, idx) => {
                        itemTargetPro.order_index = idx;
                        itemTargetPro.image = {
                            id: itemTargetPro?.image?.id ?? null
                        };
                        return itemTargetPro;
                    })
                },
                //----- end setting target product settings  --------------

                //----- start setting application method settings  --------------
                block_application_method: {
                    ...dataForm.block_application_method,
                    style: dataForm.block_application_method.style?.id ?? null,
                    background_image: dataForm?.block_application_method?.background_image?.id
                        ? {
                              id: dataForm?.block_application_method?.background_image?.id
                          }
                        : null,
                    application_methods: dataForm.block_application_method.application_methods.map((itemMethod, idx) => {
                        return {
                            ...(itemMethod.id !== null &&
                                itemMethod.id !== undefined && {
                                    id: itemMethod.id
                                }),
                            order_index: idx,
                            title: itemMethod.title,
                            image: itemMethod.image,
                            detail: itemMethod.detail,
                            status: itemMethod.status
                        };
                    })
                },
                //----- end setting application method settings  --------------

                //----- start setting receipt photography settings  --------------
                block_receipt_photography_tutorial: {
                    html_content: dataForm?.block_receipt_photography_tutorial?.html_content ?? ''
                },
                //----- end setting receipt photography settings  --------------

                //----- start setting application rule settings  --------------
                block_application_rule: {
                    ...dataForm.block_application_rule,
                    background_image: dataForm?.block_application_rule?.background_image?.id
                        ? {
                              id: dataForm?.block_application_rule?.background_image?.id
                          }
                        : null,
                    style: dataForm.block_application_rule.style?.id ?? null
                },
                //----- end setting application rule settings  --------------

                //----- start setting share settings  --------------
                block_share: {
                    ...dataForm.block_share,
                    background_image: dataForm?.block_share?.background_image?.id
                        ? {
                              id: dataForm?.block_share?.background_image?.id
                          }
                        : null,
                    style: dataForm.block_share.style?.id ?? null
                },
                //----- end setting share settings  --------------

                //----- start setting contact settings  --------------
                block_contact: {
                    ...dataForm.block_contact,
                    background_image: dataForm?.block_contact?.background_image?.id
                        ? {
                              id: dataForm?.block_contact?.background_image?.id
                          }
                        : null,
                    style: dataForm.block_contact.style?.id ?? null
                },
                //----- end setting contact settings  --------------

                //----- start setting free blocks settings  --------------
                free_blocks:
                    dataForm?.free_blocks?.map((itemFree) => {
                        return {
                            ...(itemFree.id !== null &&
                                itemFree.id !== undefined && {
                                    id: itemFree.id
                                }),
                            title: itemFree.title,
                            category: itemFree.category,
                            html_content: itemFree.html_content
                        };
                    }) ?? [],
                //----- start setting free blocks settings  --------------

                //----- start setting button blocks settings  --------------
                button_blocks:
                    dataForm?.button_blocks?.map((itemButton) => {
                        return {
                            ...(itemButton.id !== null &&
                                itemButton.id !== undefined && {
                                    id: itemButton.id
                                }),
                            title_block: itemButton.title_block,
                            title_button: itemButton.title_button,
                            url: itemButton.url,
                            style: itemButton?.style?.id ?? null,
                            color: itemButton.color,
                            attribute: itemButton.attribute
                        };
                    }) ?? []
                //----- start setting button blocks settings  --------------
            })
    };
}

function handleDataResponse(dataForm, data, dataCampain) {
    let { event_prizes } = dataCampain;

    dataForm.id = data?.id ?? null;
    dataForm.zac_id = data?.campaign?.zac_id;
    dataForm.sfid = data?.campaign?.sfid;
    dataForm.number_cp = data?.campaign?.code;
    dataForm.campaign_entity_id = data?.campaign?.campaign_entity_id;
    dataForm.sale_staff = data?.instruction_pic ?? data?.campaign?.sale_staff ?? '';
    dataForm.person_in_charge = data?.business_pic ?? data?.campaign?.person_in_charge;
    dataForm.campaign = data?.campaign ?? null;
    dataForm.code = data?.code ?? null;
    dataForm.name = data?.name ?? '';
    dataForm.url_public = data?.url_public;
    dataForm.lp_start_time = formatDateCommon(data?.lp_start_date, 'dateTime');
    dataForm.lp_end_time = formatDateCommon(data?.lp_end_date, 'dateTime');
    dataForm.campaign_start_time = formatDateCommon(data?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(data?.campaign_end_date, 'dateTime');
    dataForm.lp_start_date = formatDateCommon(data?.lp_start_date, 'date');
    dataForm.lp_end_date = formatDateCommon(data?.lp_end_date, 'date');
    dataForm.campaign_start_date = formatDateCommon(data?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(data?.campaign_end_date, 'date');
    dataForm.status = data?.status;
    dataForm.keywords = data?.keywords;
    dataForm.description = data?.description;
    dataForm.analytics = data?.analytics;
    dataForm.og_image = data?.og_image ?? null;

    //----- start setting blocks --------------
    if (data?.block_arrangement) {
        dataForm.blocks_settings = data?.block_arrangement?.map((itemArrangement) => {
            itemArrangement.key = itemArrangement?.key === 'prize_settings' ? 'block_prize' : itemArrangement.key;
            return {
                block_default: itemArrangement.type === 'predefined',
                name: itemArrangement.name,
                order_index: itemArrangement.position,
                is_visibled: itemArrangement.status === 1,
                category: itemArrangement?.category ?? '',
                key: itemArrangement.key ?? '',
                body_html: handleMapHTML(itemArrangement.key, data)
            };
        });
    }
    //----- end setting blocks --------------

    //----- start setting firstview --------------
    dataForm.first_view = {
        html_content: data?.first_view?.html_content ?? ''
    };
    dataForm.application_period = {
        title: data?.application_period?.title ?? '応募期間',
        style: data?.application_period?.style_id ? LIST_STYLE_TIME.find((oStyle) => oStyle.id === data?.application_period?.style_id) : null,
        start_date: data?.application_period?.start_date,
        end_date: data?.application_period?.end_date,
        html_content: data?.application_period?.html_content ?? ''
    };
    //----- end setting firstview --------------

    //----- start setting prize settings  --------------
    dataForm.prize_settings = {
        ...(data?.block_prize?.id !== null &&
            data?.block_prize?.id !== undefined && {
                id: data?.block_prize?.id
            }),
        title: data.block_prize?.title_block ?? '賞品',
        background: {
            image: data.block_prize?.background_image ?? null,
            color: data.block_prize?.background_color ?? '#ffffff'
        },
        is_repeat: data.block_prize?.is_repeat ? (data.block_prize?.is_repeat === 0 ? false : true) : false,
        style: data.block_prize?.style ? LIST_STYLE_PRIZE.find((oStyle) => oStyle.id === data.block_prize?.style) : null,
        html_content: data.block_prize?.html_content ?? '',
        prizes: handleResponePrize(data.block_prize?.prizes, event_prizes) ?? []
    };
    //----- end setting prize settings  --------------

    //----- start setting target product settings  --------------
    dataForm.block_target_product = {
        ...(data?.block_target_product?.id !== null &&
            data?.block_target_product?.id !== undefined && {
                id: data?.block_target_product?.id
            }),
        title_block: data?.block_target_product?.title_block ?? 'Trương trình quay số trúng thưởng !',
        background_image: data?.block_target_product?.background_image ?? null,
        background_color: data?.block_target_product?.background_color ?? '#ffffff',
        is_repeat: data?.block_target_product?.is_repeat ? (data?.block_target_product?.is_repeat === 0 ? false : true) : false,
        style: data?.block_target_product?.style ? LIST_STYLE_TARGET_PRODUCT.find((oStyle) => oStyle.id === data?.block_target_product?.style) : null,
        target_products: data?.block_target_product?.target_products ?? [],
        html_content: data?.block_target_product?.html_content ?? ''
    };
    //----- end setting target product settings  --------------

    //----- start setting application method settings  --------------
    dataForm.block_application_method = {
        ...(data?.block_application_method?.id !== null &&
            data?.block_application_method?.id !== undefined && {
                id: data?.block_application_method?.id
            }),
        title_block: data?.block_application_method?.title_block ?? '応募方法',
        background_image: data?.block_application_method?.background_image ?? null,
        background_color: data?.block_application_method?.background_color ?? '#ffffff',
        is_repeat: data?.block_application_method?.is_repeat ? (data?.block_application_method?.is_repeat === 0 ? false : true) : false,
        style: data.block_application_method?.style
            ? LIST_STYLE_APPLICATION_METHODS.find((oStyle) => oStyle.id === data.block_application_method?.style)
            : null,
        application_methods:
            data?.block_application_method?.application_methods?.map((itemAppM) => {
                itemAppM.status = itemAppM.status === 0 ? false : true;
                itemAppM.detail = itemAppM.detail ?? '';
                return itemAppM;
            }) ?? [],
        html_content: data?.block_application_method?.html_content ?? ''
    };
    //----- end setting application method settings  --------------

    //----- start setting receipt photography settings  --------------
    dataForm.block_receipt_photography_tutorial = {
        html_content: data.block_receipt_photography_tutorial?.html_content ?? ''
    };
    //----- end setting receipt photography settings  --------------

    //----- start setting application rule settings  --------------
    dataForm.block_application_rule = {
        ...(data?.block_application_rule?.id !== null &&
            data?.block_application_rule?.id !== undefined && {
                id: data?.block_application_rule?.id
            }),
        title_block: data?.block_application_rule?.title_block ?? '応募規約',
        background_image: data?.block_application_rule?.background_image ?? null,
        background_color: data?.block_application_rule?.background_color ?? '#ffffff',
        is_repeat: data?.block_application_rule?.is_repeat ? (data?.block_application_rule?.is_repeat === 0 ? false : true) : false,
        style: data.block_application_rule?.style
            ? LIST_STYLE_APPLICATION_RULE.find((oStyle) => oStyle.id === data.block_application_method?.style)
            : null,
        html_content: data?.block_application_rule?.html_content ?? ''
    };
    //----- end setting application rule settings  --------------

    //----- start setting share settings  --------------
    dataForm.block_share = {
        ...(data?.block_share?.id !== null &&
            data?.block_share?.id !== undefined && {
                id: data?.block_share?.id
            }),
        title_block: data?.block_share?.title_block ?? 'シェアブロック',
        background_image: data?.block_share?.background_image ?? null,
        background_color: data?.block_share?.background_color ?? '#ffffff',
        is_repeat: data?.block_share?.is_repeat ? (data?.block_share?.is_repeat === 0 ? false : true) : false,
        url: data?.block_share?.url ?? '',
        share_text: data?.block_share?.share_text ?? '',
        html_content: data?.block_share?.html_content ?? '',
        hashtag: data?.block_share?.hashtag ?? [],
        platform: {
            facebook: data?.block_share?.platform?.facebook ? (data?.block_share?.platform?.facebook === 0 ? false : true) : false,
            line: data?.block_share?.platform?.line ? (data?.block_share?.platform?.line === 0 ? false : true) : false,
            x: data?.block_share?.platform?.x ? (data?.block_share?.platform?.x === 0 ? false : true) : false,
            instagram: data?.block_share?.platform?.instagram ? (data?.block_share?.platform?.instagram === 0 ? false : true) : false,
            tiktok: data?.block_share?.platform?.tiktok ? (data?.block_share?.platform?.tiktok === 0 ? false : true) : false,
            pinterest: data?.block_share?.platform?.pinterest ? (data?.block_share?.platform?.pinterest === 0 ? false : true) : false
        }
    };
    //----- end setting share settings  --------------

    //----- start setting contact settings  --------------
    dataForm.block_contact = {
        ...(data?.block_contact?.id !== null &&
            data?.block_contact?.id !== undefined && {
                id: data?.block_contact?.id
            }),
        title_block: data?.block_contact?.title_block ?? 'お問い合わせ',
        background_image: data?.block_contact?.background_image ?? null,
        background_color: data?.block_contact?.background_color ?? '#ffffff',
        is_repeat: data?.block_contact?.is_repeat ? (data?.block_contact?.is_repeat === 0 ? false : true) : false,
        style: data.block_contact?.style ? LIST_STYLE_CONTACT.find((oStyle) => oStyle.id === data.block_contact?.style) : null,
        contact_secretariat_name: data?.block_contact?.contact_secretariat_name ?? dataCampain?.inquiry_office ?? '',
        tel: data?.block_contact?.tel ?? dataCampain?.master_order_scope_phone_number ?? '',
        mail: data?.block_contact?.mail ?? dataCampain?.contact_information_email ?? '',
        html_content: data?.block_contact?.html_content ?? ''
    };
    //----- end setting contact settings  --------------

    //----- start setting free blocks settings  --------------
    dataForm.free_blocks = data?.free_blocks ?? [];
    //----- start setting free blocks settings  --------------

    //----- start setting button blocks settings  --------------
    dataForm.button_blocks = data?.button_blocks ?? [];
    //----- start setting button blocks settings  --------------

    SOCIAL_MEDIA_SHARE.forEach((itemSocial) => {
        itemSocial.active = data?.block_share?.platform[itemSocial.key] ?? false;
    });

    return dataForm;
}

function handleResponePrize(prizeLP, prizeCP) {
    let getPizes = [];
    let imgPrizeDefault = '/images/landingpage/prize_default.jpg';
    if (prizeLP) {
        getPizes = prizeLP.map((itemPrze) => {
            itemPrze.image = itemPrze?.image ?? {
                path: imgPrizeDefault,
                extension: 'jpg'
            };
            itemPrze.status = itemPrze.status === 0 ? false : true;
            itemPrze.detail = itemPrze.detail ?? '';
            return itemPrze;
        });
        return getPizes;
    }
    getPizes = prizeCP?.map((itemPrze, idx) => {
        return {
            make_id: idx + 1,
            typeAction: 'Edit',
            title: itemPrze.title,
            image: {
                path: imgPrizeDefault,
                extension: 'jpg'
            },
            detail: '',
            number_of_winner: itemPrze.number_of_win_prize,
            status: true
        };
    });
    return getPizes;
}

function handleMapDataFromStateLP(dataForm, allInforCampaignLP) {
    dataForm.sale_staff = allInforCampaignLP?.sale_staff ?? '';
    dataForm.person_in_charge = allInforCampaignLP?.person_in_charge ?? '';
    dataForm.zac_id = allInforCampaignLP?.zac_id;
    dataForm.sfid = allInforCampaignLP?.sfid;
    dataForm.number_cp = allInforCampaignLP?.code;

    dataForm.campaign_entity_id = allInforCampaignLP?.campaign_entity_id;
    dataForm.number_cp = allInforCampaignLP?.code;
    dataForm.campaign_start_time = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'dateTime');
    dataForm.campaign_end_time = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'dateTime');
    dataForm.campaign_start_date = formatDateCommon(allInforCampaignLP?.campaign_start_date, 'date');
    dataForm.campaign_end_date = formatDateCommon(allInforCampaignLP?.campaign_end_date, 'date');
    return dataForm;
}

function validateEndDate(startDateStr, endDateStr) {
    let check = true;
    const startDate = moment(startDateStr.valueSync, STR_DATE_TIME);
    const endDate = moment(endDateStr.valueSync, STR_DATE_TIME);
    if (!startDateStr.valueSync && !endDateStr.valueSync) return check;
    if (!endDate.isSameOrAfter(startDate)) {
        check = false;
        endDateStr.error = true;
        endDateStr.errorText = i18n.t('validateField.compareDate');
    }
    return check;
}

function handleAlertValidateTabs(conditionTab, bvToast, modelTab) {
    let check = true;
    const getArrTAB = TABS();
    const tabBasic = !conditionTab.checkBasicLP || !conditionTab.checkEndDateCP || !conditionTab.checkEndDateLP;

    const getNameTab = getArrTAB[modelTab]?.name ?? '';
    const titleTab = {
        basic: i18n.t('landingPage.list.tabBasic'),
        time: i18n.t('landingPage.list.tabTimeApp'),
        prize: i18n.t('landingPage.list.tabPrizes'),
        target_products: i18n.t('landingPage.list.tabTargetProduct'),
        method_app: i18n.t('landingPage.list.tabMethodsApp'),
        rule_app: i18n.t('landingPage.list.tabRuleApp'),
        share: i18n.t('landingPage.list.tabShareBlock'),
        contact: i18n.t('landingPage.list.tabContact')
    };

    const tabTimeApplication = !conditionTab.checkTimeApplication;
    const tabPrize = !conditionTab.checkTabPrize;
    const tabTargetProduct = !conditionTab.checkTabTargetProduct;
    const tabApplicationMethods = !conditionTab.checkTabApplicationMethods;
    const tabApplicationRule = !conditionTab.checkTabApplicationRule;
    const tabShare = !conditionTab.checkTabShare;
    const tabContact = !conditionTab.checkTabContact;

    if (tabBasic && getNameTab !== titleTab.basic) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.basic
            })
        );
    }

    if (tabTimeApplication && getNameTab !== titleTab.time) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.time
            })
        );
    }

    if (tabPrize && getNameTab !== titleTab.prize) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.prize
            })
        );
    }

    if (tabTargetProduct && getNameTab !== titleTab.target_products) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.target_products
            })
        );
    }

    if (tabApplicationMethods && getNameTab !== titleTab.method_app) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.method_app
            })
        );
    }

    if (tabApplicationRule && getNameTab !== titleTab.rule_app) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.rule_app
            })
        );
    }

    if (tabShare && getNameTab !== titleTab.share) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.share
            })
        );
    }

    if (tabContact && getNameTab !== titleTab.contact) {
        showMessage(
            8080,
            bvToast,
            i18n.t('validateField.tabs', {
                field: titleTab.contact
            })
        );
    }

    if (tabBasic || tabTimeApplication || tabPrize || tabTargetProduct || tabApplicationMethods || tabApplicationRule || tabShare || tabContact) {
        check = false;
    }

    return check;
}
function handleMapHTML(paramKey, dataRes) {
    if (!dataRes[paramKey]) return '';
    return dataRes[paramKey]?.html_content ?? '';
}

function handleRecordLPError(router, idLP, bvToast) {
    Swal.fire({
        title: 'LPエラー',
        text: `キャンペーンが削除されたため、このLPを削除しますか。`,
        icon: 'error',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '',
        cancelButtonColor: '#eff2f7',
        confirmButtonText: `${i18n.t('btn.ok')}`,
        cancelButtonText: `<div style="color: #212529">${i18n.t('btn.cancel')}</div>`
    }).then(async (result) => {
        if (result.value) {
            const response = await landingPageService.deleteLandingPage({ id: Number(idLP) });
            router.push({ path: '/landing-page/list' }).then(() => {
                showMessage(response.code, bvToast, 'LPが削除されました。');
            });
        } else {
            router.push({
                path: '/landing-page/list'
            });
        }
    });
}
export {
    handleDataBeforeSaveLP,
    handleMapDataFromStateLP,
    validateEndDate,
    handleDataResponse,
    handleAlertValidateTabs,
    handleRecordLPError,
    handleMapHTML
};
