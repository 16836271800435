var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[(_vm.$props.screen === 2)?_c('div',[_c('div',[_c('div',{staticClass:"card-header"},[(_vm.userInfo.team_id)?_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    sale_staff_team: _vm.userInfo.team_id,
                                    progress_status: _vm.sttEvent1.toString()
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 運用中 ) ")]):_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    progress_status: _vm.sttEvent1.toString()
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 運用中 ) ")])]),_c('div',{staticClass:"card-body"},[_c('TableBox',{attrs:{"list-data":_vm.listDataCPProcessing,"infor-page":_vm.processing,"is-loading":_vm.isLoadingCPProcessing}})],1)]),_c('div',[_c('div',{staticClass:"card-header"},[(_vm.userInfo.team_id)?_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    sale_staff_team: _vm.userInfo.team_id,
                                    progress_status: _vm.sttEvent2.toString()
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 開催前 ) ")]):_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    progress_status: _vm.sttEvent2.toString()
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 開催前 ) ")])]),_c('div',{staticClass:"card-body"},[_c('TableBox',{attrs:{"list-data":_vm.listDataCPBeforeTheEvent,"infor-page":_vm.beforeTheEvent,"is-loading":_vm.isLoadingCPBeforeTheEvent}})],1)])]):_vm._e(),(_vm.$props.screen === 3)?_c('div',[_c('div',[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){return _vm.$router.push({
                                path: '/campaign/list',
                                query: { is_approved: 0 }
                            })}}},[_vm._v(" 承認待ちキャンペーン ")])]),_c('div',{staticClass:"card-body"},[_c('TableBox',{attrs:{"list-data":_vm.listDataCampain,"infor-page":_vm.paginationCP,"is-loading":_vm.isLoadingDataCP}})],1)]),_c('div',[_c('div',{staticClass:"card-header"},[(_vm.userInfo.team_id)?_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    team_id: _vm.userInfo.team_id,
                                    progress_status: _vm.sttEvent1.toString(),
                                    is_approved: 1
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 運用中 ) ")]):_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    progress_status: _vm.sttEvent1.toString(),
                                    is_approved: 1
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 運用中 ) ")])]),_c('div',{staticClass:"card-body"},[_c('TableBox',{attrs:{"list-data":_vm.listDataCPProcessing,"infor-page":_vm.processing,"is-loading":_vm.isLoadingCPProcessing}})],1)]),_c('div',[_c('div',{staticClass:"card-header"},[(_vm.userInfo.team_id)?_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    team_id: _vm.userInfo.team_id,
                                    progress_status: _vm.sttEvent2.toString(),
                                    is_approved: 1
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 開催前 ) ")]):_c('h3',{staticClass:"mb-0 title--click title--screen",on:{"click":function($event){_vm.$router.push({
                                path: '/campaign/list',
                                query: {
                                    progress_status: _vm.sttEvent2.toString(),
                                    is_approved: 1
                                }
                            })}}},[_vm._v(" キャンペーン一覧 ( 開催前 ) ")])]),_c('div',{staticClass:"card-body"},[_c('TableBox',{attrs:{"list-data":_vm.listDataCPBeforeTheEvent,"infor-page":_vm.beforeTheEvent,"is-loading":_vm.isLoadingCPBeforeTheEvent}})],1)])]):_vm._e()])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }